//coloque aqui a url do heroku
const url = "https://subtitulos-8ee6161f9c85.herokuapp.com"

export const subtliteVideo = async (formData) => {
    //faz uma requisição la na heroku para a parte de subtlite-video
    //Pede o metodo: se o usuario fornecer dados(video,arquivo,texto) é Post, se somente o backend fornece dados é get
    //o formData é onde vai os arquivos do usurio
    const response = await fetch(url + "/api/tools/subtitle-video", {
        method: "POST",
        body: formData,
    })

    const result = await response.json()

    return result.videoUrl
}
